import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Brand from '../components/brand'
import Divider from '../components/divider'
import StaLink from '../components/link'

export default ({pageContext}) => {
  const {menu, order, institutional} = pageContext

  return (
    <Layout>
      <SEO title="Casa Santa Thereza" />

      <div className="grid-container">
        <header id="Head" className="grid-item">
          <Brand />

          <div style={{marginTop: 'auto'}}>
            <h1
              dangerouslySetInnerHTML={{
                __html: institutional.frontmatter.description,
              }}
            />
          </div>
        </header>

        <Divider />

        <div id="Institutional" className="grid-item">
          <div dangerouslySetInnerHTML={{__html: institutional.html}} />
        </div>

        <Divider />

        <div id="Menu" className="grid-item">
          <h2 dangerouslySetInnerHTML={{__html: menu.frontmatter.title}} />
          <div id="MenuTitle" dangerouslySetInnerHTML={{__html: menu.html}} />
        </div>

        <Divider />

        <div id="Orders" className="grid-item">
          <h2 dangerouslySetInnerHTML={{__html: order.frontmatter.title}} />
          <div id="OrdersData" dangerouslySetInnerHTML={{__html: order.html}} />
        </div>

        <Divider />

        <footer id="Contact" className="grid-item">
          <StaLink
            title="Endereço no GoogleMaps"
            href="https://goo.gl/maps/59ReD8NRvXSayZx88"
            html={institutional.frontmatter.address}
          />

          <div className="endAlign">
            <StaLink
              title="WhatsApp"
              href="https://wa.me/+5567999064923"
              html={institutional.frontmatter.phone}
            />

            <StaLink
              className="insta-link"
              title="Instagram"
              href="https://www.instagram.com/casasantathereza"
              html={institutional.frontmatter.ig}
            />
          </div>
        </footer>
      </div>
    </Layout>
  )
}
