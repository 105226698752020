import React from 'react'
import ThemeContext from '../context/ThemeContext'

const Divider = () => (
  <ThemeContext.Consumer>
    {theme => (
      <div
        className="grid-item divider"
        style={{
          gridColumn: '1 / 3',
          borderBottom: `1px solid ${theme.dark ? 'black' : 'var(--orange)'}`,
        }}
      />
    )}
  </ThemeContext.Consumer>
)

export default Divider
