import React from 'react'

import ThemeContext from '../context/ThemeContext'

const StaLink = props => (
  <ThemeContext.Consumer>
    {theme => (
      <a
        className={props.className ? props.className : null}
        style={{color: theme.dark ? 'black' : 'var(--orange)'}}
        title={props.title}
        href={props.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span dangerouslySetInnerHTML={{__html: props.html}} />
      </a>
    )}
  </ThemeContext.Consumer>
)

export default StaLink
